import React from 'react'
import Seo from '../components/seo'
import Layout from '../components/layout'
import BoxWithImage from '../components/box-with-image'
import BoxWithIcon from '../components/box-with-icon'
import { title, subtitle } from '../styles/landing.module.css'
import { sectionSuperTitle, sectionTitle, hero, left } from '../styles/home.module.css'
import { graphql } from 'gatsby'
import { getImage, StaticImage } from 'gatsby-plugin-image'
import StoreButton from '../components/store-button'

const IndexPage = ({ data }) => {
  return (
    <Layout>
      <section className={hero + ' mt-4 mb-5 mb-xl-0 text-center text-xl-start'}>
        <div className='row align-items-center'>
          <div className={'col-xl-6 mb-4 mb-xl-0' + left}>
            <h5 className={sectionSuperTitle}>PUBLIC BETA</h5>
            <h1 className={'mt-4 mt-xl-0 ' + title}>Experience the first chainless wallet on Kadena</h1>
            <div className={subtitle + ' mt-3 mb-5 mt-xl-0'}>Linx, your easy way into DeFi and NFTs <span className={"text-secondary"}>on the most scalable and secure blockchain.</span></div>
            <StoreButton store="apple" />
            <StoreButton store="google" />
          </div>
          <div className='col-xl-6 mt-4 mt-sm-0 px-5 px-xl-0 linx-background'>
            <StaticImage alt="Linx" src="../images/hero.png" loading='eager' placeholder='blurred' />
          </div>
        </div>
      </section>

      <section className={"text-center p-4 mb-5"}>
        <h5 className={sectionSuperTitle}>FEATURES</h5>
        <h2 className={sectionTitle}>Explore beta features</h2>
        <div className='row mt-5'>
          <BoxWithImage title="Chainless Transactions" className={'col-sm-6 col-md-4 mb-3'} image={getImage(data.chainlessTransactions)}>
            Easy transfers, Linx takes care of all the complicated stuff. Don't worry about chains, we got you covered.
          </BoxWithImage>
          <BoxWithImage title="Manage Tokens" className={'col-sm-6 col-md-4 mb-3'} image={getImage(data.manageTokens)}>
            Linx supports all tokens on the Kadena network. No need to manually add tokens in your Linx wallet.
          </BoxWithImage>
          <BoxWithImage title="Manage Chains" className={'col-sm-6 col-md-4 mb-3'} image={getImage(data.manageChains)}>
            Have full control over your chains, for any transaction you can choose all chains or select individual ones.
          </BoxWithImage>
          <BoxWithImage title="WalletConnect" className={'col-sm-6 col-md-4 mb-3'} image={getImage(data.walletConnect)}>
            Simply scan a QR and easily connect to any Dapp on Kadena with WalletConnect V2.
          </BoxWithImage>
          <BoxWithImage title="Swap" className={'col-sm-6 col-md-4 mb-3'} image={getImage(data.swap)}>
            Swap any token on any DEX protocol running on Kadena without leaving Linx wallet.
          </BoxWithImage>
          <BoxWithImage title="Secure Wallet" className={'col-sm-6 col-md-4 mb-3'} image={getImage(data.secureWallet)}>
            Linx uses the standard BIP39, to generate an ED25519 HD wallet. Your sensitive data is stored encrypted on your phone only.
          </BoxWithImage>
          <BoxWithImage title="NFT's" className={'col-sm-6 col-md-4 mb-3'} image={getImage(data.tradeNFTS)}>
            Only on Linx, NFT Marketplaces are able to let you trade your NFT's through WalletConnect.
          </BoxWithImage>
          <BoxWithImage title="Dark and Light Mode" className={'col-sm-6 col-md-4 mb-3'} image={getImage(data.darkLightMode)}>
            Customize your Linx wallet and easily change the look and feel of it.
          </BoxWithImage>
          <BoxWithImage title="Dapp Integrations" className={'col-sm-6 col-md-4 mb-3'} image={getImage(data.dapp)}>
            Get detailed information on any token, explore chains and transaction history.
          </BoxWithImage>
        </div>
      </section>

      <section className={"text-center p-4 mb-5"}>
        <h5 className={sectionSuperTitle}>COMING SOON</h5>
        <h2 className={sectionTitle}>We are working on</h2>
        <div className='row mt-5'>
          <BoxWithIcon title='Fiat On-Ramp' className='col-sm-6 col-md-4 mb-3' iconUrl={data.fiatOnRamp.publicURL}>
            Buy Kadena within Linx wallet, and pay with Google Pay, Apple Pay, Credit Card or Wire Transfer.
          </BoxWithIcon>
          <BoxWithIcon title='Multi-Sig' className='col-sm-6 col-md-4 mb-3' iconUrl={data.multisig.publicURL}>
            Manage accounts that require multiple signatures and easily add signatures to Multi-Sig transactions.
          </BoxWithIcon>
          <BoxWithIcon title="Multi Account" className='col-sm-6 col-md-4 mb-3' iconUrl={data.multiAccount.publicURL}>
            Create, manage and easily switch between accounts in Linx wallet.
          </BoxWithIcon>
        </div>
      </section>

      <section className={"text-center pt-4 px-4 linx-background"} style={{ marginBottom: '10rem' }}>
        <h5 className={sectionSuperTitle}>PUBLIC BETA</h5>
        <h1 className={title + " mt-4 mb-4"}>Experience the first chainless wallet on Kadena</h1>
        <div className={subtitle + " mb-5"}>Linx, your easy way into DeFi and NFTs <span className={"text-secondary"}>on the most scalable and secure blockchain.</span></div>
        <StoreButton store="apple" />
        <StoreButton store="google" />
      </section>
    </Layout>
  )
}

export const query = graphql`
  query {
    manageChains: file(relativePath: {eq: "images/features/manage-chains.png"}) {
      childImageSharp {
        gatsbyImageData
      }
    },
    manageTokens: file(relativePath: {eq: "images/features/manage-tokens.png"}) {
      childImageSharp {
        gatsbyImageData
      }
    },
    chainlessTransactions: file(relativePath: {eq: "images/features/chainless-transactions.png"}) {
      childImageSharp {
        gatsbyImageData
      }
    },
    walletConnect: file(relativePath: {eq: "images/features/walletconnect.png"}) {
      childImageSharp {
        gatsbyImageData
      }
    },
    swap: file(relativePath: {eq: "images/features/swap.png"}) {
      childImageSharp {
        gatsbyImageData
      }
    },
    secureWallet: file(relativePath: {eq: "images/features/secure-wallet.png"}) {
      childImageSharp {
        gatsbyImageData
      }
    },
    tradeNFTS: file(relativePath: {eq: "images/features/trade-nfts.png"}) {
      childImageSharp {
        gatsbyImageData
      }
    },
    darkLightMode: file(relativePath: {eq: "images/features/dark-light-mode.png"}) {
      childImageSharp {
        gatsbyImageData
      }
    },
    dapp: file(relativePath: {eq: "images/features/dapp.png"}) {
      childImageSharp {
        gatsbyImageData
      }
    },
    fiatOnRamp: file(relativePath: {eq: "images/soon/fiat-on-ramp.svg"}) {
      publicURL
    },
    nfts: file(relativePath: {eq: "images/soon/nfts.svg"}) {
      publicURL
    },
    multisig: file(relativePath: {eq: "images/soon/multi-sig.svg"}) {
      publicURL
    },
    integrations: file(relativePath: {eq: "images/soon/integrations.svg"}) {
      publicURL
    },
    multiAccount: file(relativePath: {eq: "images/soon/multi-account.svg"}) {
      publicURL
    }
  }
`
export const Head = ({ location }) => <Seo title="Your key to Web3 on Kadena" locationPath={location.pathname} />
export default IndexPage
