import * as React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import * as boxWithImageStyles from './box-with-image.module.css'

const BoxWithImage = ({ image, title, className, children }) => {
    return (
        <div className={className}>
            <div className={boxWithImageStyles.box + ' p-5'}>
                <div className={boxWithImageStyles.imageShadow}>
                    {<GatsbyImage image={image} alt={title} loading='eager' placeholder='blurred' />}
                </div>
                <div className={boxWithImageStyles.title + ' mt-4'}>{title}</div>
                <div className={boxWithImageStyles.body}>{children}</div>
            </div>
        </div >
    )
}

export default BoxWithImage