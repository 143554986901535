import * as React from 'react'
import * as boxWithIconStyles from './box-with-icon.module.css'

const BoxWithIcon = ({ iconUrl, title, className, children }) => {
    return (
        <div className={className}>
            <div className={boxWithIconStyles.box}>
                <img src={iconUrl} alt={title} />
                <div className={boxWithIconStyles.title + ' mt-4'}>{title}</div>
                <div className={boxWithIconStyles.body}>{children}</div>
            </div>
        </div>
    )
}

export default BoxWithIcon