import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

const StoreButton = ({ store}) => {
  const googleButtonClasses = `play-store-button mt-md-5 mt-4`;
  const iOSButtonClasses = `app-store-button mt-md-5 mt-4 me-md-4`;

  if (store === "apple") {
    return <a href="https://apps.apple.com/us/app/thinedgelabs-linx-wallet/id6450412379" target='_blank' rel="noreferrer" className={iOSButtonClasses}>
      <StaticImage alt="Linx" src="../images/app-store.png" height={60} loading='eager' placeholder='blurred' />
    </a>
  }
  else if (store === "google") {
    return <a href="https://play.google.com/store/apps/details?id=com.thinedgelabs.linx_wallet" target='_blank' rel="noreferrer" className={googleButtonClasses}>
      <StaticImage alt="Linx" src="../images/google-play.png" loading='eager' height={60} placeholder='blurred' />
    </a>
  }
  return;
}

export default StoreButton